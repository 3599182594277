import React, { useRef, useEffect, useCallback } from 'react';
import gsap from 'gsap';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';
import { getLanguageSlug } from './../utils/helpers';
import { useOnClickOutside } from './../utils/custom-hooks';

const BuildingsModal = ({ children, allPages, isClosing, toggleRenderModal }) => {
	const linkRefs = useRef([]);
	const modalBlockRef = useRef();
	const { t, i18n } = useTranslation();
	const { allFile: { fullwidthImages }} = useStaticQuery(graphql`
		query Images {
			allFile(filter: {relativeDirectory: {eq: "changebuildings"}}) {
				fullwidthImages: edges {
					node {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								placeholder: BLURRED
								formats: [AVIF, WEBP, JPG]
							)
							resize {
								originalName
								src
							}
						}
					}
				}
			}
		}
	`);
	useOnClickOutside(linkRefs, () => toggleRenderModal());

	const getImageObject = (fileName, images) => {
		if(!images) { return; }
		const index = images.findIndex(image => image.node.childImageSharp.resize.originalName === fileName);
		return index > -1 ? images[index].node.childImageSharp.gatsbyImageData : {};
	};

	const getPath = (page) => {
		const languageSlug = getLanguageSlug(i18n.language);
		return page.isFrontpage ? languageSlug : `${languageSlug}${page.slug}`;
	};

	const animateSliding = reverseAnimation => {
		const animation = {
			yPercent: -100,
			duration: 1,
			ease: 'Expo.easeOut',
		};

		if (reverseAnimation) {
			return gsap.to(modalBlockRef.current, animation);
		}
		gsap.set(modalBlockRef.current, { autoAlpha: 1 });
		return gsap.from(modalBlockRef.current, animation);
	};

	const addElementRefs = useCallback(element => {
		if (!linkRefs.current.includes(element)) {
			linkRefs.current.push(element);
		}
	}, []);

	const reloadPage = page => {
		const isCurrentPage = window.location.pathname === getPath(page);
		if (isCurrentPage) return window.location.reload();
	};

	useEffect(() => {
		linkRefs.current = [
			...linkRefs.current,
			document.querySelector('.header__link'),
			document.querySelector('.header__select'),
			document.querySelector('.header__burger-menu'),
		];
	}, []);

	useEffect(() => {
		animateSliding(isClosing);
	}, [isClosing]);

	return (
		<div className="buildings-modal" ref={modalBlockRef}>
			<p className="buildings-modal__text">
				{t('header.changeBuildingLabel')}
			</p>
			<div className="buildings-modal__inner-wrapper">
				{allPages && allPages.map((page, index) => (
					<div
						key={page.slug}
						ref={element => addElementRefs(element)}
						className='buildings-modal__page-wrapper'
					>
						<div className='building-modal__block'>
							<TransitionLink to={getPath(page)} onClick={() => reloadPage(page)} activeClassName="buildings-modal__page-link">
								<GatsbyImage
									image={getImageObject(t(`buildings.pages.${index}.details.previewImage`), fullwidthImages)}
									alt={`picture of ${t(`buildings.pages.${index}.name`)}`}
								/>
								<div className='buildings-modal__page-details'>
									<h2 className='buildings-modal__page-name'>
										{(index + 1).toString().padStart(2, '0')} {t(`buildings.pages.${index}.name`)}
									</h2>
									<p>{t('global.location')}: {t(`buildings.pages.${index}.details.location`)}</p>
									<p>{t('global.buildingStart')}: {t(`buildings.pages.${index}.details.started`)}</p>
									<p>{t('global.buildingEnd')}: {t(`buildings.pages.${index}.details.ended`)}</p>
									<p>{t('global.areal')}: {t(`buildings.pages.${index}.details.areal`)} <span>m<sup>2</sup></span></p>
									<p>{t('global.contractor')}: {t(`buildings.pages.${index}.details.contractor`)}</p>
									<p>{t('global.architects')}: {t(`buildings.pages.${index}.details.architects`)}</p>
									<p>{t('global.engineer')}: {t(`buildings.pages.${index}.details.engineer`)}</p>
									<p>{t('global.entrepreneur')}: {t(`buildings.pages.${index}.details.entrepreneur`)}</p>
								</div>
							</TransitionLink>
						</div>
					</div>
				))}
				{children}
			</div>
		</div>
	);
};

export default BuildingsModal;