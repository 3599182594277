import { useEffect, useRef } from 'react';

export const usePrevious = value => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
};

export const useOnClickOutside = (ref, handler) => {

	useEffect(() => {
		const listener = (event) => {
			if (!ref.current) {
				return;
			}

			if (Array.isArray(ref.current)) {
				if (ref.current.some(el => el?.contains(event.target))) {
					return;
				}
			} else if (!Array.isArray(ref.current)) {
				if (ref.current.contains(event.target)) {
					return;
				}
			}

			// Do nothing if clicking ref's element or descendent elements
			handler(event);
		};
		
		document.addEventListener('mousedown', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
		};
	},
	// Add ref and handler to effect dependencies
	// It's worth noting that because passed in handler is a new ...
	// ... function on every render that will cause this effect ...
	// ... callback/cleanup to run every render. It's not a big deal ...
	// ... but to optimize you can wrap handler in useCallback before ...
	// ... passing it into this hook.
	[ref, handler]);
};