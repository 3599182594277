import Bowser from 'bowser';
import avifTestImage from './../images/test-1x1.avif';
import webpTestImage from './../images/test-1x1.webp';

export const isBrowser = typeof window !== undefined;

export const prefixZeroToNumber = num => {
	return num < 10 ? `0${num}` : num;
};

export const isAvifSupported = () => {
	return new Promise(resolve => {
		const avif = new Image();
		avif.onload = () => resolve(true);
		avif.onerror = () => resolve(false);
		avif.src = avifTestImage;
	});
};

export const isWebpSupported = () => {
	return new Promise(resolve => {
		const webp = new Image();
		webp.onload = () => resolve(true);
		webp.onerror = () => resolve(false);
		webp.src = webpTestImage;
	});
};

export const isHevcSupported = () => {
	const videoElement = document.createElement('video');
	return videoElement.canPlayType('video/mp4; codecs=hvc1');
};

export const isSafari = () => {
	const browser = Bowser.getParser(window.navigator.userAgent);
	const browserDetails = browser.getBrowser();
	return browserDetails.name === 'Safari';
};

export const isMobile = () => {
	const browser = Bowser.getParser(window.navigator.userAgent);
	return browser.getPlatformType() === 'mobile';
};

export const isTouchDevice = () => {
	return (('ontouchstart' in window) ||
	(navigator.maxTouchPoints > 0) ||
	(navigator.msMaxTouchPoints > 0));
};

export const getLanguageSlug = langCode => {
	if (process.env.GATSBY_DEFAULT_LANGUAGE === langCode) {
		return '/';
	}
	return `/${langCode}/`;
};

export const testNetworkConnection = (imageSource, downloadSize) => {
	const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
	const startTime = (new Date()).getTime();

	const getResults = endTime => {
		const duration = (endTime - startTime) / 1000;
		const bitsLoaded = downloadSize * 8;
		const speedBps = (bitsLoaded / duration).toFixed(2);
		const speedKbps = (speedBps / 1024).toFixed(2);
		return (speedKbps / 1024).toFixed(2);
	};

	return new Promise((resolve, reject) => {	
		if (connection) {
			return resolve(connection.downlink);
		}
		const download = new Image();
		download.onload = () => {
			const speedMbps = getResults((new Date()).getTime());
			return resolve(speedMbps);
		};
		download.onerror = () => reject();
		download.src = imageSource;
	});
};

export const getFrameRate = mbps => {
	if (mbps > 8) return 30;
	if (mbps > 5) return 20;
	if (mbps > 2) return 15;
	return 10;
};

export const getMaxFrames = (totalFrames, mbps) => {
	const initialFrameRate = 30;
	const newFrameRate = getFrameRate(mbps);
	const downgradeInPercentage = newFrameRate / initialFrameRate * 100;
	return Math.ceil(totalFrames * downgradeInPercentage / 100);
};