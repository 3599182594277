import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'gatsby-plugin-react-i18next';

export const HeaderBlock = ({ children, mobile }) => (
	<header className={clsx('header', {
		'header--mobile': mobile,
	})}>
		{children}
	</header>
);

export const HeaderInnerWrapper = ({ children }) => (
	<div className="header__inner-wrapper">
		{children}
	</div>
);

export const HeaderLogoWrapper = ({ children, url, altText }) => (
	<a href={url} className="header__logo-wrapper" alt={altText}>
		{children}
	</a>
);

export const HeaderBurgerMenu = ({ children, handleClick, isBurgerCollapsed }) => (
	<button onClick={handleClick} className={clsx('header__burger-menu', {
		'header__burger-menu--collapsed': isBurgerCollapsed,
	})}>
		{children}
	</button>
);

export const HeaderScreenReaderText = ({ text }) => (
	<span className="sr-only" dangerouslySetInnerHTML={{ __html: text }} />
);

export const HeaderNavigation = ({ children, hideMobile }) => (
	<nav className={clsx('header__navigation', {
		'header__navigation--hidden-mobile': hideMobile,
	})}>
		{children}
	</nav>
);

export const HeaderSecondaryNavigation = ({ children }) => (
	<ul className="header__secondary-navigation">
		{children}
	</ul>
);

export const HeaderSecondaryNavigationItem = ({ children, languageSwitcher }) => (
	<li className={clsx('header__secondary-navigation__item', {
		'header__secondary-navigation__item--language-switcher': languageSwitcher,
	})}>
		{children}
	</li>
);

export const HeaderSecondaryNavigationButton = ({ children, handleClick, styled }) => (
	<button className={clsx({'header__secondary-navigation__button': styled })} onClick={handleClick}>
		{children}
	</button>
);

export const HeaderLanguageSwitcherDropdown = ({ children, hideLanguageList }) => (
	<ul className={clsx('header__language-switcher-dropdown', {
		'header__language-switcher-dropdown--active': !hideLanguageList,
	})}>
		{children}
	</ul>
);

export const HeaderLanguageSwitcherDropdownItem = ({ children, hideLanguageList }) => (
	<li
		className={clsx('header__language-switcher-dropdown__item', {
			'header__language-switcher-dropdown__item--hidden': hideLanguageList,
		})}
	>
		{children}
	</li>
);

export const HeaderLanguageSwitcherDropdownLink = ({ handleClick, language }) => {
	const { i18n } = useTranslation();
	return (
		<button
			className={clsx('header__language-switcher-dropdown__item__link', {
				'header__language-switcher-dropdown__item__link--active': i18n.language === language,
			})}
			onClick={handleClick}
		>
			{language}
		</button>
	);
};

export const HeaderSecondaryNavigationLink = ({ url, children }) => (
	<a href={url} target="_blank" rel="noopener noreferrer" className="header__secondary-navigation__button">
		{children}
	</a>
);

export const HeaderMainNavigation = ({ children }) => (
	<ul className="header__main-navigation">
		{children}
	</ul>
);

export const HeaderMainNavigationItem = ({ children }) => (
	<li className="header__main-navigation__item">
		{children}
	</li>
);

export const HeaderButton = ({ children, handleClick, red, active }) => (
	<button
		onClick={handleClick}
		className={clsx('header__link', {
			'header__link--red': red,
			'header__link--active': active,
		})}
	>
		{children}
	</button>
);

export const HeaderLink = ({ children, url, external }) => (
	<a
		href={url}
		target="_blank"
		rel="noopener noreferrer"
		className={clsx('header__link', {
			'link--external': external,
		})}
	>
		{children}
	</a>
);

export const HeaderLabel = ({ children }) => (
	<label className="header__label">
		{children}
	</label>
);

export const HeaderSelect = ({ children, handleChange, value }) => (
	<select value={value} onChange={handleChange} className="header__select" >
		{children}
	</select>
);