import React, { useState, useEffect } from 'react';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import clsx from 'clsx';
import {
	HeaderBlock,
	HeaderInnerWrapper,
	HeaderLogoWrapper,
	HeaderNavigation,
	HeaderMainNavigation,
	HeaderMainNavigationItem,
	HeaderButton,
	HeaderLink,
	HeaderSecondaryNavigation,
	HeaderSecondaryNavigationItem,
	HeaderSecondaryNavigationButton,
	HeaderLanguageSwitcherDropdown,
	HeaderLanguageSwitcherDropdownItem,
	HeaderLanguageSwitcherDropdownLink,
	HeaderBurgerMenu,
	HeaderScreenReaderText,
	HeaderSecondaryNavigationLink,
	HeaderLabel,
	HeaderSelect,
} from './header';
import Logo from './../svg/logo';
import GlobeIcon from './../svg/globe-icon';
import BuildingsModal from './buildings-modal';
import { getLanguageSlug } from '../utils/helpers';

const Layout = ({ children, allPages, minHeight }) => {
	const [renderModal, setRenderModal] = useState(false);
	const [isModalClosing, setIsModalClosing] = useState(true);
	const [hideLanguageList, setHideLanguageList] = useState(true);
	const { t, i18n } = useTranslation();
	const { language, languages, changeLanguage } = useI18next();

	const handleModalTransition = () => {
		const renderTimeout = setTimeout(() => {
			setRenderModal(false);
		}, 1000);

		if (!isModalClosing) {
			clearTimeout(renderTimeout);
			setRenderModal(true);
		}
	};

	const showLanguageDropdown = () => setHideLanguageList(!hideLanguageList);
	const toggleRenderModal = () => setIsModalClosing(!isModalClosing);

	useEffect(() => {
		handleModalTransition();
	}, [isModalClosing]);

	return (
		<div>
			<HeaderBlock>
				<HeaderInnerWrapper>
					<HeaderLogoWrapper url={getLanguageSlug(i18n.language)} altText="Home">
						<Logo className="header__logo" />
					</HeaderLogoWrapper>
					<HeaderBurgerMenu handleClick={toggleRenderModal} isBurgerCollapsed={!isModalClosing}>
						<HeaderScreenReaderText text="Toggle menu" />
					</HeaderBurgerMenu>
					<HeaderNavigation hideMobile>
						<HeaderMainNavigation>
							<HeaderMainNavigationItem>
								<HeaderButton red active handleClick={toggleRenderModal}>
									{t('header.changeBuildingLabel')}
								</HeaderButton>
							</HeaderMainNavigationItem>
							<HeaderMainNavigationItem>
								<HeaderLink url={t('header.mainSiteUrl')} external>
									{t('header.mainSiteLabel')}
								</HeaderLink>
							</HeaderMainNavigationItem>
							<HeaderSecondaryNavigationItem languageSwitcher>
								<HeaderSecondaryNavigationButton handleClick={showLanguageDropdown}>
									<GlobeIcon className="header__globe-icon" />
									<HeaderScreenReaderText text={t('header.language')} />
								</HeaderSecondaryNavigationButton>
								<HeaderLanguageSwitcherDropdown hideLanguageList={hideLanguageList}>
									{languages.map((language) =>
										<HeaderLanguageSwitcherDropdownItem key={language} hideLanguageList={hideLanguageList}>
											<HeaderLanguageSwitcherDropdownLink language={language} handleClick={() => changeLanguage(language)}>
												{language}
											</HeaderLanguageSwitcherDropdownLink>
										</HeaderLanguageSwitcherDropdownItem>,
									)}
								</HeaderLanguageSwitcherDropdown>
							</HeaderSecondaryNavigationItem>
						</HeaderMainNavigation>
					</HeaderNavigation>
				</HeaderInnerWrapper>
			</HeaderBlock>
			<main className={clsx('container w-full mx-auto main-container', {
				'min-h-screen': minHeight,
			})}>
				{children}
			</main>
			<div className="js-portal-spawnpoint">
				{renderModal && (
					<BuildingsModal
						allPages={allPages}
						isClosing={isModalClosing}
						toggleRenderModal={toggleRenderModal}
					>
						<HeaderBlock mobile>
							<HeaderInnerWrapper>
								<HeaderNavigation>
									<HeaderSecondaryNavigation>
										<HeaderSecondaryNavigationItem languageSwitcher>
											<HeaderLabel>
												<GlobeIcon className="header__globe-icon" />
												{t('header.language')}
												<HeaderSelect value={language} handleChange={event => changeLanguage(event.target.value)}>
													{languages.map((language) =>
														<option key={language} value={language}>
															{(language).toUpperCase()}
														</option>,
													)}
												</HeaderSelect>
											</HeaderLabel>
										</HeaderSecondaryNavigationItem>
										<HeaderSecondaryNavigationItem>
											<HeaderSecondaryNavigationLink url={t('header.mainSiteUrl')}>
												{t('header.mainSiteLabel')}
											</HeaderSecondaryNavigationLink>
										</HeaderSecondaryNavigationItem>
									</HeaderSecondaryNavigation>
								</HeaderNavigation>
							</HeaderInnerWrapper>
						</HeaderBlock>
					</BuildingsModal>
				)}
			</div>
		</div>
	);
};

export default Layout;