import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { MorphSVGPlugin } from 'gsap/MorphSVGPlugin';

const timelineConfig = gsap.timeline({
	defaults: {
		ease: 'linear',
		repeat: -1,
		duration: 4,
		yoyo:true,
	},
});
const timeline1 = timelineConfig;
const timeline2 = timelineConfig;

const GlobeIcon = ({ ...args }) => {
	const animatedPathRef1 = useRef(null);
	const animatedPathRef2 = useRef(null);

	const createGlobeAnimation = () => {

		timeline1.to([animatedPathRef1.current ],{
			morphSVG: 'M7.5,14.7V0.3',
		}, 0)
			.to([animatedPathRef1.current ], {
				morphSVG: 'M7.5,14.5c3.9,0,7-3.1,7-7s-3.1-7-7-7',
			}, 1);

		timeline2.to([animatedPathRef2.current ],{
			morphSVG: 'M7.5,14.7V0.3',
		}, 0)
			.to([animatedPathRef2.current ], {
				morphSVG: 'M7.5,14.5c-3.9,0-7-3.1-7-7s3.1-7,7-7',
			}, 1);
	};
	useEffect(() => {
		gsap.registerPlugin(MorphSVGPlugin);
		createGlobeAnimation();
	}, []);

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15" {...args} >
			<circle fill="none" stroke="#000" strokeWidth="1" cx="7.5" cy="7.5" r="7"/>
			<path ref={animatedPathRef1} fill="none" stroke="#000" strokeWidth="1" d="M7.5,14.5c-3.9,0-7-3.1-7-7s3.1-7,7-7"/>
			<path fill="none" stroke="#000" strokeWidth="1" d="M7.5,14.7V0.3"/>
			<path fill="none" stroke="#000" strokeWidth="1" d="M0.3,7.5h14.3"/>
			<path ref={animatedPathRef2} fill="none" stroke="#000" strokeWidth="1" d="M7.5,14.5c3.9,0,7-3.1,7-7s-3.1-7-7-7"/>
		</svg>
	);
};

export default GlobeIcon;