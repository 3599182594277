import React from 'react';

const Logo = ({ ...args }) => (
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 989.8 306.1" xmlSpace="preserve" {...args}>
		<g>
			<path style={{ fill: '#D20014' }} d="M267.8,114.8h33c3.7,0,7.5,0.4,11.2,1.1c3.7,0.8,7,2.1,9.9,4c2.9,1.9,5.3,4.5,7.1,7.7
				c1.8,3.2,2.7,7.2,2.7,12.1c0,4.7-1.2,8.8-3.5,12.4c-2.3,3.6-5.6,6.3-9.9,8.3l19.4,31h-29.6L293.6,164h-0.8v27.3h-25V114.8z
				M292.6,147.1h5c0.9,0,1.8-0.1,2.8-0.2c1-0.1,2-0.4,2.9-0.9c0.9-0.5,1.6-1.1,2.3-1.9c0.6-0.8,0.9-1.9,0.9-3.3
				c0-1.4-0.3-2.4-0.8-3.2c-0.5-0.8-1.1-1.4-1.9-1.8c-0.8-0.4-1.6-0.6-2.6-0.8c-0.9-0.1-1.8-0.2-2.6-0.2h-6.1V147.1z"/>
			<path style={{ fill: '#D20014' }} d="M334.6,152.6c0-6.1,1.1-11.7,3.2-16.6c2.2-4.9,5.1-9.1,8.9-12.6c3.8-3.5,8.3-6.1,13.5-8
				c5.2-1.9,10.8-2.8,16.9-2.8c6.1,0,11.7,0.9,16.9,2.8c5.2,1.9,9.7,4.5,13.5,8c3.8,3.5,6.8,7.7,9,12.6c2.2,4.9,3.2,10.5,3.2,16.6
				c0,6.1-1.1,11.7-3.2,16.8c-2.2,5-5.2,9.3-9,12.9c-3.8,3.6-8.3,6.3-13.5,8.3c-5.2,1.9-10.8,2.9-16.9,2.9c-6.1,0-11.7-1-16.9-2.9
				c-5.2-1.9-9.7-4.7-13.5-8.3s-6.8-7.9-8.9-12.9C335.6,164.3,334.6,158.7,334.6,152.6z M361.9,152.6c0,2.5,0.4,4.8,1.1,6.9
				c0.8,2.1,1.8,3.9,3.1,5.4c1.3,1.5,2.9,2.7,4.8,3.5c1.9,0.8,3.9,1.2,6.1,1.2s4.2-0.4,6-1.2c1.8-0.8,3.4-2,4.8-3.5
				c1.4-1.5,2.4-3.3,3.2-5.4c0.8-2.1,1.1-4.4,1.1-6.9c0-2.4-0.4-4.7-1.1-6.8c-0.8-2.1-1.8-3.8-3.2-5.2c-1.4-1.4-3-2.5-4.8-3.3
				c-1.8-0.8-3.8-1.2-6-1.2s-4.2,0.4-6.1,1.2c-1.9,0.8-3.5,1.9-4.8,3.3c-1.3,1.4-2.4,3.1-3.1,5.2C362.3,147.9,361.9,150.2,361.9,152.6
				z"/>
			<path style={{ fill: '#D20014' }} d="M422.5,153.1c0-6.1,1.1-11.7,3.2-16.6c2.2-5,5.1-9.2,8.9-12.8c3.7-3.5,8.2-6.3,13.2-8.2
				c5.1-1.9,10.5-2.9,16.3-2.9c5.9,0,11.5,1,16.8,2.9c5.3,1.9,9.7,4.5,13.2,7.7l-15.8,19c-1.4-1.9-3.2-3.3-5.4-4.3s-4.6-1.5-7.2-1.5
				c-2.2,0-4.2,0.4-6.1,1.1c-1.9,0.8-3.5,1.9-4.9,3.3c-1.4,1.4-2.5,3.2-3.3,5.2c-0.8,2.1-1.2,4.3-1.2,6.9c0,2.5,0.4,4.8,1.2,6.8
				c0.8,2,1.9,3.7,3.4,5.1c1.4,1.4,3,2.5,4.9,3.3c1.9,0.8,3.9,1.1,5.9,1.1c3,0,5.5-0.6,7.6-1.8c2.1-1.2,3.8-2.7,5-4.3l15.8,18.9
				c-3.5,3.5-7.7,6.2-12.8,8.3c-5,2.1-10.8,3.1-17.2,3.1c-5.8,0-11.2-1-16.3-2.9c-5.1-1.9-9.5-4.7-13.2-8.3c-3.7-3.6-6.7-7.8-8.9-12.8
				S422.5,159.1,422.5,153.1z"/>
			<path style={{ fill: '#D20014' }} d="M522.3,160.8h-0.2v30.5h-25v-76.5H522V143h0.2l20.4-28.2h30.3l-28.8,35l30.2,41.5h-31.6L522.3,160.8z"/>
			<path style={{ fill: '#D20014' }} d="M619,191.3H593l-20.9-76.5h27.2l8.2,43h0.4l8.9-43h26.9l9.6,43h0.4l8.3-43h26.4l-21.3,76.5h-25.9l-10.8-43.2
				h-0.4L619,191.3z"/>
			<path style={{ fill: '#D20014' }} d="M684.5,152.6c0-6.1,1.1-11.7,3.2-16.6c2.2-4.9,5.1-9.1,8.9-12.6c3.8-3.5,8.3-6.1,13.5-8
				c5.2-1.9,10.8-2.8,16.9-2.8c6.1,0,11.7,0.9,16.9,2.8c5.2,1.9,9.7,4.5,13.5,8c3.8,3.5,6.8,7.7,9,12.6c2.2,4.9,3.2,10.5,3.2,16.6
				c0,6.1-1.1,11.7-3.2,16.8c-2.2,5-5.2,9.3-9,12.9c-3.8,3.6-8.3,6.3-13.5,8.3c-5.2,1.9-10.8,2.9-16.9,2.9c-6.1,0-11.7-1-16.9-2.9
				c-5.2-1.9-9.7-4.7-13.5-8.3s-6.8-7.9-8.9-12.9C685.6,164.3,684.5,158.7,684.5,152.6z M711.9,152.6c0,2.5,0.4,4.8,1.1,6.9
				c0.8,2.1,1.8,3.9,3.1,5.4c1.3,1.5,2.9,2.7,4.8,3.5c1.9,0.8,3.9,1.2,6.1,1.2c2.2,0,4.2-0.4,6-1.2c1.8-0.8,3.4-2,4.8-3.5
				c1.4-1.5,2.4-3.3,3.2-5.4c0.8-2.1,1.1-4.4,1.1-6.9c0-2.4-0.4-4.7-1.1-6.8c-0.8-2.1-1.8-3.8-3.2-5.2c-1.4-1.4-3-2.5-4.8-3.3
				c-1.8-0.8-3.8-1.2-6-1.2c-2.2,0-4.2,0.4-6.1,1.2c-1.9,0.8-3.5,1.9-4.8,3.3c-1.3,1.4-2.4,3.1-3.1,5.2
				C712.3,147.9,711.9,150.2,711.9,152.6z"/>
			<path style={{ fill: '#D20014' }} d="M772.4,152.6c0-6.1,1.1-11.7,3.2-16.6c2.2-4.9,5.1-9.1,8.9-12.6c3.8-3.5,8.3-6.1,13.5-8
				c5.2-1.9,10.8-2.8,16.9-2.8c6.1,0,11.7,0.9,16.9,2.8c5.2,1.9,9.7,4.5,13.5,8c3.8,3.5,6.8,7.7,9,12.6c2.2,4.9,3.2,10.5,3.2,16.6
				c0,6.1-1.1,11.7-3.2,16.8c-2.2,5-5.2,9.3-9,12.9c-3.8,3.6-8.3,6.3-13.5,8.3c-5.2,1.9-10.8,2.9-16.9,2.9c-6.1,0-11.7-1-16.9-2.9
				c-5.2-1.9-9.7-4.7-13.5-8.3s-6.8-7.9-8.9-12.9C773.5,164.3,772.4,158.7,772.4,152.6z M799.8,152.6c0,2.5,0.4,4.8,1.1,6.9
				c0.8,2.1,1.8,3.9,3.1,5.4c1.3,1.5,2.9,2.7,4.8,3.5c1.9,0.8,3.9,1.2,6.1,1.2c2.2,0,4.2-0.4,6-1.2c1.8-0.8,3.4-2,4.8-3.5
				c1.4-1.5,2.4-3.3,3.2-5.4c0.8-2.1,1.1-4.4,1.1-6.9c0-2.4-0.4-4.7-1.1-6.8c-0.8-2.1-1.8-3.8-3.2-5.2c-1.4-1.4-3-2.5-4.8-3.3
				c-1.8-0.8-3.8-1.2-6-1.2c-2.2,0-4.2,0.4-6.1,1.2c-1.9,0.8-3.5,1.9-4.8,3.3c-1.3,1.4-2.4,3.1-3.1,5.2
				C800.2,147.9,799.8,150.2,799.8,152.6z"/>
			<path style={{ fill: '#D20014' }} d="M862,114.8h25.9v54.4h25.4v22.2H862V114.8z"/>
			<path style={{ fill: '#D20014' }} d="M76.5,76.5v153.1h153.1V76.5H76.5z"/>
		</g>
		<path style={{ fill: '#D20014' }} d="M894.9,123.8c0-1.3,0.2-2.5,0.7-3.7c0.5-1.1,1.2-2.1,2-2.9c0.8-0.8,1.8-1.5,2.9-1.9c1.1-0.5,2.3-0.7,3.6-0.7
			c1.2,0,2.4,0.2,3.6,0.7c1.1,0.5,2.1,1.1,2.9,1.9c0.8,0.8,1.5,1.8,2,2.9c0.5,1.1,0.7,2.3,0.7,3.6c0,1.3-0.2,2.5-0.7,3.7
			c-0.5,1.1-1.2,2.1-2,2.9c-0.8,0.8-1.8,1.5-2.9,1.9c-1.1,0.5-2.3,0.7-3.6,0.7c-1.3,0-2.5-0.2-3.6-0.7c-1.1-0.5-2.1-1.1-2.9-1.9
			c-0.8-0.8-1.5-1.8-2-2.9C895.1,126.3,894.9,125.1,894.9,123.8z M896.3,123.8c0,1.1,0.2,2.1,0.6,3.1c0.4,0.9,1,1.8,1.7,2.5
			c0.7,0.7,1.5,1.2,2.5,1.6c0.9,0.4,1.9,0.6,3,0.6c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.8-1,2.5-1.7c0.7-0.7,1.3-1.5,1.7-2.5
			c0.4-1,0.6-2,0.6-3.1c0-1.1-0.2-2.1-0.6-3.1c-0.4-1-1-1.8-1.7-2.5c-0.7-0.7-1.5-1.2-2.5-1.6c-0.9-0.4-1.9-0.6-3-0.6
			c-1.1,0-2.1,0.2-3,0.6c-0.9,0.4-1.8,1-2.5,1.6c-0.7,0.7-1.3,1.5-1.7,2.5C896.5,121.7,896.3,122.7,896.3,123.8z M900.7,118.6h3.9
			c1.3,0,2.2,0.2,2.8,0.7c0.6,0.5,0.9,1.3,0.9,2.3c0,1-0.3,1.6-0.8,2.1c-0.5,0.4-1.2,0.7-2,0.8l3.1,4.7h-1.6l-2.9-4.5h-1.9v4.5h-1.5
			V118.6z M902.1,123.3h1.8c0.4,0,0.7,0,1.1,0c0.3,0,0.7-0.1,0.9-0.2s0.5-0.3,0.6-0.5c0.2-0.2,0.2-0.6,0.2-1c0-0.4-0.1-0.7-0.2-0.9
			c-0.1-0.2-0.3-0.4-0.6-0.5c-0.2-0.1-0.5-0.2-0.8-0.2c-0.3,0-0.6,0-0.8,0h-2.3V123.3z"/>
		<polygon style={{ fill: '#FFFFFF' }} points="147.3,162.6 130.1,191.3 91.8,191.3 137.7,114.8 147.3,114.8 "/>
		<polygon style={{ fill: '#FFFFFF' }} points="176,191.3 158.8,162.6 158.8,114.8 168.4,114.8 214.3,191.3 "/>
	</svg>
);

export default Logo;